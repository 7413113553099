import { Injectable } from '@angular/core';
import {ModalService} from "../modal/modal.service";
import {FeLoaderComponent} from "../../components/fe-loader/fe-loader.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private dialogRef: MatDialogRef<any> = null;
  constructor(public dialog: MatDialog) { }

  public showLoader(show: boolean, text: string) {
    if (show) {
      if (this.dialogRef != null) {
        this.dialogRef.close();
        this.dialogRef = this.dialog.open(FeLoaderComponent,
            {data: {loaderText: text, fromService: true}, disableClose: true});
      } else {
        this.dialogRef = this.dialog.open(FeLoaderComponent,
            {data: {loaderText: text, fromService: true}, disableClose: true});
      }
    } else {
      if (this.dialogRef != null) {
        this.dialogRef.close();
        this.dialogRef = null;
      }
    }
  }
}
