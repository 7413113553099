export interface ScopeMailList {
    id?: number;
    libelle?: string;
    mails?: Array<ScopeMail>;
}

export interface ScopeMail {
    id?: number;
    name?: string;
    email?: string;
}
