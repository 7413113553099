import {ScopeMail} from "./ScopeMailList";

export interface ScopeNewsletterContactLine {
    id?: number;
    type?: ScopeNewsletterContactLineType;
    libelle?: string;
    mail?: string;
    mails?: ScopeMail[];
}

export enum ScopeNewsletterContactLineType {
    mail, broadcast
}