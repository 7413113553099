import {Component, EventEmitter, Inject, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MailsService} from "../../../services/mails/mails.service";
import {DomSanitizer} from "@angular/platform-browser";

declare function compileMail(func): any;

@Component({
  selector: 'app-scope-preview-newsletter',
  templateUrl: './scope-preview-newsletter.component.html',
  styleUrls: ['./scope-preview-newsletter.component.scss']
})
export class ScopePreviewNewsletterComponent {
    @Output() confirm = new EventEmitter<any>();
    protected isLoading: Boolean = true;
    private newsletter;
    protected html: any;

    constructor(private mailService: MailsService,
                private sanitizer: DomSanitizer,
                protected dialogRef: MatDialogRef<ScopePreviewNewsletterComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        if (data != null) {
            this.newsletter = data.newsletter;
        }
        const truc = this;
        compileMail(function() {
            truc.showPreviewBridge((window as any).htmlcss);
        });
    }

    private showPreviewBridge(htmlCss: any): void {
        this.showPreview(htmlCss);
    }

    private async showPreview(htmlCss: any) {
        this.mailService.nl = this.newsletter;
        this.mailService.nl.html = htmlCss.html;
        this.html = this.sanitizer.bypassSecurityTrustHtml(this.mailService.nl.html);
        this.isLoading = false;
    }

    protected sendNewsletter(): void {
        this.confirm.emit();
        this.dialogRef.close();
    }
}
