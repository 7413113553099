import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {MailsService} from '../../../services/mails/mails.service';
import {ScopeFolder} from '../../../interfaces/newsletter/ScopeFolder';
import {NewsletterTemplate} from "../../../interfaces/newsletter/newsletter-template";
import {ModalService} from "../../../services/modal/modal.service";
import {SnackbarService} from "../../../services/snackbar/snackbar.service";
import {MatDialogRef} from "@angular/material/dialog";
import {LoaderService} from "../../../services/loader/loader.service";

@Component({
  selector: 'scope-create-newsletter',
  templateUrl: './scope-create-newsletter.component.html',
  styleUrls: ['./scope-create-newsletter.component.scss']
})
export class ScopeCreateNewsletterComponent implements OnInit {
  protected newsletterName: string = '';
  protected scopeFolders: ScopeFolder[] = [];
  protected newsletterTemplates: NewsletterTemplate[] = [];
  protected isLoadingFolder: Boolean = false;
  protected isLoadingTemplates: Boolean = false;
  protected selectedTemplate : NewsletterTemplate = null;
  protected selectedFolder : ScopeFolder = null;
  @Output() confirm = new EventEmitter<any>();

  constructor(private mailsService: MailsService,
              private modalService: ModalService,
              private snackbarService: SnackbarService,
              private loaderService: LoaderService,
              private dialogRef: MatDialogRef<ScopeCreateNewsletterComponent>) {
  }

  ngOnInit(): void {
    this.isLoadingFolder = true
    this.isLoadingTemplates = true
    this.mailsService.getScopeFolders().subscribe(value => {
      this.isLoadingFolder = false;
      if (value != null) {
        this.scopeFolders = value;
      }
    });
    this.mailsService.getNewsletterTemplates().subscribe(value => {
      this.isLoadingTemplates = false
      if (value != null) {
        this.newsletterTemplates = value;
      }
    });
  }

  protected createNewsletter(): void {
    let errors : string = '';
    let errorsCount: number = 0;

    if (this.newsletterName.length < 1) {
      errors += '<br/>- Donnez un nom à la newsletter.';
      errorsCount ++;
    }
    if (this.selectedFolder == null) {
      errors += '<br/>- Sélectionnez un dossier.';
      errorsCount ++;
    }
    if (this.selectedTemplate == null) {
      errors += '<br/>- Sélectionnez un template.';
      errorsCount ++;
    }

    if (errorsCount > 0) {
      this.snackbarService.showDangerSnackbar('Opération impossible',
          'Création impossible suite '
          + (errorsCount > 1 ? 'aux erreurs suivantes :': 'à l\'erreur suivante :')
          + errors);
    } else {
      this.modalService.confirmModal('Confirmation',
          'Voulez vous créer la newsletter "<b>' + this.newsletterName + '</b>" ?', args => {
        this.loaderService.showLoader(true, 'Création de la newsletter');
        this.mailsService.createScopeNewsletter(this.selectedFolder.folderID, this.selectedTemplate.id, this.newsletterName)
            .subscribe(value =>  {
              this.loaderService.showLoader(false, 'Création de la newsletter');
              this.snackbarService.showSuccessSnackbar('Opération réussie',
                  'La newsletter "<b>' + this.newsletterName + '</b>" a été créée avec succès.');
              this.confirm.emit(value);
              this.dialogRef.close();
            });
      });
    }
  }

  protected getResumeSelection(): String {
    let toReturn: string = '';

    if (this.selectedFolder != null) {
      toReturn += 'Dossier "<b>' + this.selectedFolder.libelle + '</b>"';
    }

    if (this.selectedTemplate != null) {
      if (toReturn.length > 0) {
        toReturn += '  -  ';
      }
      toReturn += 'Template "<b>' + this.selectedTemplate.name + '</b>"';
    }

    return toReturn;
  }

  protected selectTemplate(newsletterTemplate: NewsletterTemplate): void {
    this.selectedTemplate = newsletterTemplate;
  }

  selectFolder(scopeFolder: ScopeFolder) {
    this.selectedFolder = scopeFolder;
  }

  protected isFolderSelected(scopeFolder: ScopeFolder): boolean {
    if (this.selectedFolder == null) {
      return false;
    } else {
      return scopeFolder.folderID == this.selectedFolder.folderID;
    }
  }
}
