import {Component, EventEmitter, Inject, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ContactLineInterface} from '../fe-carnet-adresses-modal/fe-carnet-adresses-modal.component';
import {AddressInterface} from '../../../interfaces/address/address';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {SnackbarService} from '../../../services/snackbar/snackbar.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ColorType, IconType} from '../../parts/fe-icon/fe-icon.component';
import {ScopeMail, ScopeMailList} from "../../../interfaces/newsletter/ScopeMailList";
import {
  ScopeNewsletterContactLine,
  ScopeNewsletterContactLineType
} from "../../../interfaces/newsletter/ScopeNewsletterContactLine";

export interface ScopeContactLine {
  id: number;
  checked: boolean;
  label?: string;
  email?: string;
  mails?: ScopeMail[];
}

@Component({
  selector: 'scope-book-address-newsletter',
  templateUrl: './scope-book-address-newsletter.component.html',
  styleUrls: ['./scope-book-address-newsletter.component.scss']
})
export class ScopeBookAddressNewsletterComponent {
  contactControl = new FormControl('');
  protected contactsArray: AddressInterface[] = [];
  protected loading: boolean = false;
  protected contactDisplayedColumns: string[] = ['select', 'label', 'email'];
  protected broadcastDisplayedColumns: string[] = ['select', 'label', 'count'];
  protected readonly IconType = IconType;
  private filter: string;
  protected contactsLines: ScopeContactLine[] = [];
  protected broadcastsLines: ScopeContactLine[] = [];
  protected contactDataSource = new MatTableDataSource<ScopeContactLine>(this.contactsLines);
  protected broadcastDataSource = new MatTableDataSource<ScopeContactLine>(this.broadcastsLines);
  protected contactSelection: SelectionModel<ScopeContactLine> = new SelectionModel<ScopeContactLine>(true, []);
  protected broadcastSelection: SelectionModel<ScopeContactLine> = new SelectionModel<ScopeContactLine>(true, []);
  @Output() confirm = new EventEmitter<any>();

  constructor(private snackbarService: SnackbarService,
              protected dialogRef: MatDialogRef<ScopeBookAddressNewsletterComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data != null) {
      if (data.mailsList != null) {
        data.mailsList.forEach(value => {
          this.contactsLines.push({id: value.id, checked: false, label: value.name, email: value.email});
        });
      }
      if (data.broadcastsList != null) {
        data.broadcastsList.forEach(value => {
          this.broadcastsLines.push({id: value.id, checked: false, label: value.libelle, mails: value?.mails})
        });
      }
    }
  }

  protected addContactToMail(contact: ContactLineInterface): void {
    this.insertContact({addressId: contact.id, data: {label: contact.label, email: contact.email}});
  }

  private insertContact(address: AddressInterface): void {
    let notPresent: boolean = true;
    this.contactsArray.forEach(value => {
      if (value.addressId == address.addressId) {
        notPresent = false;
      }
    });
    if (notPresent) {
      this.contactsArray.push(address);
    }
  }

  private insertBroadcast(address: AddressInterface): void {
    let notPresent: boolean = true;
    this.contactsArray.forEach(value => {
      if (value.addressId == address.addressId) {
        notPresent = false;
      }
    });
    if (notPresent) {
      this.contactsArray.push(address);
    }
  }

  protected filterContactChange($event: string): void  {
    this.filter = $event;
    this.contactDataSource.filter = this.filter;
  }

  protected filterBroadcastChange($event: string): void {
    this.filter = $event;
    this.broadcastDataSource.filter = this.filter;
  }

  protected addContactsToNewsletter(): void {
    if ((this.contactSelection.selected.length + this.broadcastSelection.selected.length) > 0) {
      let contactsToReturn: ScopeNewsletterContactLine[] = [];
      let broadcastsToReturn: ScopeNewsletterContactLine[] = [];

      this.contactSelection.selected.forEach(
          contactSelected => {
            contactsToReturn.push({id: contactSelected.id, type: ScopeNewsletterContactLineType.mail,
              libelle: contactSelected.label, mail: contactSelected.email});
      });

      this.broadcastSelection.selected.forEach(
          broadcastSelected => {
            broadcastsToReturn.push({id: broadcastSelected.id, type: ScopeNewsletterContactLineType.broadcast,
              libelle: broadcastSelected.label, mails: broadcastSelected.mails});
      });

      this.confirm.emit({contacts: contactsToReturn, broadcasts: broadcastsToReturn});
      this.dialogRef.close();
    } else {
      this.snackbarService.showDangerSnackbar('Opération impossible',
          'Vous devez sélectionner au moins un contact ou un groupe.', 5);
    }
  }

  protected toggleAllContactRows(): void {
    if (this.isAllContactSelected()) {
      this.contactSelection.clear();
      return;
    }

    this.contactSelection.select(...this.contactDataSource.data);
  }

  protected toggleAllBroadcastRows(): void {
    if (this.isAllBroadcastSelected()) {
      this.broadcastSelection.clear();
      return;
    }

    this.broadcastSelection.select(...this.broadcastDataSource.data);
  }

  protected isAllContactSelected(): boolean {
    const numSelected = this.contactSelection.selected.length;
    const numRows = this.contactDataSource.data.length;
    return numSelected === numRows && numSelected > 0;
  }

  protected isAllBroadcastSelected(): boolean {
    const numSelected = this.broadcastSelection.selected.length;
    const numRows = this.broadcastDataSource.data.length;
    return numSelected === numRows && numSelected > 0;
  }

  protected getContactsLabel(): string {
    let toReturn: string = this.contactSelection.selected.length + " ";
    if (this.contactSelection.selected.length > 1) {
      toReturn += 'contacts sélectionnés';
    } else {
      toReturn += 'contact sélectionné';
    }
    return toReturn;
  }

  protected getBroadcastsLabel(): string {
    let toReturn: string = this.broadcastSelection.selected.length + " ";
    if (this.broadcastSelection.selected.length > 1) {
      toReturn += 'groupes sélectionnés';
    } else {
      toReturn += 'groupe sélectionné';
    }
    return toReturn;
  }

  protected getResumeLabel(): string {
    let toReturn: string = this.contactSelection.selected.length + " ";
    if (this.contactSelection.selected.length > 1) {
      toReturn += 'contacts';
    } else {
      toReturn += 'contact';
    }
    toReturn += "  -  " + this.broadcastSelection.selected.length + " ";
    if (this.broadcastSelection.selected.length > 1) {
      toReturn += 'groupes';
    } else {
      toReturn += 'groupe';
    }
    return toReturn;
  }

  protected checkboxContactLabel(row?: ContactLineInterface): string {
    if (!row) {
      return `${this.isAllContactSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.contactSelection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  protected checkboxBroadcastLabel(row?: ContactLineInterface): string {
    if (!row) {
      return `${this.isAllBroadcastSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.broadcastSelection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  protected readonly ColorType = ColorType;
}
