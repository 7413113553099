import {Injectable} from '@angular/core';
import {OAuthErrorEvent, OAuthService} from 'angular-oauth2-oidc';
import {AUTH_CONFIG} from 'src/app/config/AuthConfig';
import {from, Observable, throwError} from 'rxjs';
import {LoginInterface} from 'src/app/interfaces/login/login.interface';
import {NavController, Platform} from '@ionic/angular';
import {AppPreferencesService} from '../app-preferences/app-preferences.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpHeaders} from '@angular/common/http';
import {ToastService} from '../toast/toast.service';
import {Utils} from '../../classes/Utils';

declare var Fingerprint2: any;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public fingerprint: string;

  public roleCom: boolean = false;
  public authOk: boolean = false;
  private overwatch: NodeJS.Timeout;

  constructor(
      private oauthService: OAuthService
      , private navController: NavController
      , private appPreferencesService: AppPreferencesService
      , private platform: Platform
      , private route: ActivatedRoute
      , private toastService: ToastService
      , private router: Router
  ) {
    this.getFingerprint();
    this.init();
  }

  getFingerprint() {
    const self = this;
    if (this.fingerprint === undefined) {
      const options = {
        excludeJsFonts: true,
        excludeFlashFonts: true,
        excludeAudioFP: true,
        excludeScreenResolution: true,
        excludeAvailableScreenResolution: true,
        excludeSessionStorage: true,
        excludeOpenDatabase: true,
        excludeIndexedDB: true
      };
      return Fingerprint2.get(options, function (components) {
        const values = components.map(function(component) {
          return component.value;
        });
        self.fingerprint = Fingerprint2.x64hash128(values.join(''), 31);
        return self.fingerprint;
      });
    } else {
      return self.fingerprint;
    }
  }

  /**
   * Init Oauth service and register eventListener from Oauth
   */
  public init(): void {

    this.oauthService.configure(AUTH_CONFIG);
    this.oauthService.setupAutomaticSilentRefresh();

    if (this.getParameterByName('role') === 'com') {
      this.roleCom = true;
    }

    // listener for event from OAuth
    this.oauthService.events.subscribe((event: OAuthErrorEvent) => {
      // logout event => delete login preferences
      if (event.type === 'logout') {
        const token = Utils.getParameterByName('token');
        // delete token from userPref
        this.appPreferencesService.remove('token')
        .then(() => this.appPreferencesService.remove('loginData'))
        .then(() => {
          // routing to login page.
          if (Utils.isScope) {
            this.navController.navigateRoot('loginscope?token=' + token);
          }
          else {
            this.navController.navigateRoot('login?token=' + token);
          }
        });
      }
    });
  }

  getParameterByName(name: any) {
    const url = window.location.href;
    name = name.replace(/[[]]/g, '\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) { return undefined; }
    if (!results[2]) { return ''; }
    return decodeURIComponent(results[2].replace('/+/g', ' '));
  }

  /**
   * Fetch token from Oauth server
   * @param loginData LoginInterface {identifier: string, password: string}
   */
  public fetchTokenUsingCredential(loginData: LoginInterface): Observable<boolean> {
    // console.log(loginData);
    // loginData.identifier = loginData.identifier.toLowerCase();
    // console.log(loginData);
    let headers = new HttpHeaders();
    const f = this.getFingerprint();
    if (f) {
      headers = headers.set('X-Fingerprint', f);
    }
    if (this.roleCom) {
      headers = headers.set('X-Role-Com', 'OO64QW6LBS3NG8Z0HZAO');
    }
    let login = loginData.identifier;
    // if (this.platform.is('ios') || this.platform.is('android')) {
    // if (this.platform.is('cordova')) {
    //   if (!this.platform.is('desktop') && !this.platform.is('mobileweb')) {
    if (!this.platform.is('desktop') && !this.platform.is('mobileweb')) {
        // if (!this.platform.url().startsWith('http')) {
      login = 'mobile:' + login;
    }
    let loginOath = login;
    if (loginData.external) {
      loginOath = loginOath + '|auth_product_cel';
    }
    else if (loginData.scope) {
      loginOath = loginOath + '|auth_product_scope';
    }
    else {
      loginOath = loginOath + '|auth_product_firsteco';
    }
    return from(this.oauthService.fetchTokenUsingPasswordFlow(loginOath, loginData.password, headers)
    .then(((tokenData: any) => {
      this.appPreferencesService.userToken = loginData.identifier;
      this.appPreferencesService.set('token', tokenData.access_token);
      this.appPreferencesService.userToken = loginData.identifier;
      return this.appPreferencesService.set('loginData', JSON.stringify(loginData));
    }))
    .then((res) => {
      // on mobile, session storage are clean after each start...
      // so oauth token is invalid.
      // we store loginData in AppPreference to autoconnect user
      // if (this.platform.is('ios') || this.platform.is('android')) {
        this.appPreferencesService.userToken = loginData.identifier;
        return this.appPreferencesService.set('loginData', JSON.stringify(loginData));
      // } else {
      //   return true;
      // }
    })
    .catch(error => {
      try {
        if (error.error.error_description === 'User account is locked') {
          // this.toastService.simple('Vous êtes déjà connecté depuis un autre emplacement', { duration: 6000, position: 'bottom', color: 'toasterror' });
          this.toastService.simple('Votre compte d\'accès à la plateforme First ECO est strictement personnel. <br>Le nombre de connexions simultanées à votre compte est limité. <br>Votre chargé d\'affaires se tient à votre disposition si vous souhaitez obtenir des comptes utilisateurs supplémentaires.<br><br>L\'équipe First ECO', {
            duration: 60000,
            color: 'toasterror'
          });
        } else if (error.error.error_description === 'User account is disabled') {
          this.toastService.simple('Aucun compte n\'existe pour cet identifiant.<br><br>L\'équipe First ECO', {duration: 60000, color: 'toasterror'});
        }
        throw throwError(error);
      } catch (e) {
        throw throwError(error);
      }
      // return of(false);
    }));
  }

  printObject(o) {
    let out = '';
    for (let p in o) {
      if (o.hasOwnProperty(p)) {
        out += p + ': ' + o[p] + '\n';
      }
    }
    alert(out);
  }

  /**
   * Logout method
   */
  public logout(): void {
    this.oauthService.logOut();
    this.appPreferencesService.remove('loginData');
    this.appPreferencesService.remove('token');
    this.appPreferencesService.remove('userToken');
    Utils.userToken = undefined;
    clearInterval(this.overwatch);
  }

  /**
   * has valid acces token ?
   */
  public hasValidAccessToken(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  /**
   * return the stored token
   */
  public getStoredToken(): Promise<any> {
    return this.appPreferencesService.get('token');
  }

  public fetchTokenViaLink(username: string, pass: string): Observable<boolean> {
    // username = username.toLowerCase();
    const loginData: LoginInterface = { identifier: username, password: pass };
    return this.fetchTokenUsingCredential(loginData);
  }

  /**
   * refresh token and save new token in appPreferencesService
   */
  public refreshToken(): Observable<boolean> {
    return from(this.oauthService.refreshToken()
    .then((tokenData: any) => this.appPreferencesService.set('token', tokenData.access_token)));
  }

  setOverwatch(overwatch: NodeJS.Timeout) {
    this.overwatch = overwatch;
  }

  initFromEntreprise() {
    this.oauthService.configure(AUTH_CONFIG);
    this.oauthService.setupAutomaticSilentRefresh();
  }
}
