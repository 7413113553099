import {Component, Input} from '@angular/core';
import {FavorisFolder} from "../../../interfaces/favoritesFolders/favorites-folders.interface";

@Component({
  selector: 'scope-folder-item',
  templateUrl: './scope-folder-item.component.html',
  styleUrls: ['./scope-folder-item.component.scss']
})
export class ScopeFolderItemComponent {
  @Input() folderName: String;
  @Input() folderCount: String;
  @Input() selectedFolder: boolean = false;
}
