<div class="scopeBookAddressNewsletter feModal">
    <div class="feModalHeader">
        <span class="feModalTitle">Carnet d’adresses</span>
        <div class="feModalCloseButton">
            <span mat-dialog-close>Fermer</span>
            <mat-icon mat-dialog-close>close</mat-icon>
        </div>
    </div>
    <div class="feModalContent">
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="feMatTabTitle">
                        <span>Liste d'adresses</span>
                    </div>
                </ng-template>

                <div class="searchContactsContainer">
                    <fe-searchbar placeHolder="Chercher un contact..." (valueChange)="filterContactChange($event)"/>
                </div>

                <div class="contactOptions">
                    <mat-checkbox (change)="$event ? toggleAllContactRows() : null"
                                  [checked]="contactSelection.hasValue() && isAllContactSelected()"
                                  [indeterminate]="contactSelection.hasValue() && !isAllContactSelected()"
                                  [aria-label]="checkboxContactLabel()">
                    </mat-checkbox>
                    <span [ngClass]="{'noArticlesSelected': this.contactsLines.length === 0}"
                          *ngIf="contactSelection.selected.length > 0">
                      {{ getContactsLabel() }}
                    </span>
                </div>

                <div class="contactsTableContainer" [class.displayNone]="loading">
                    <table mat-table [dataSource]="contactDataSource" matSort #contactLinesSort="matSort"
                           class="mat-elevation-z8">

                        <!-- Checkbox Column -->
                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                              (change)="$event ? contactSelection.toggle(row) : null"
                                              [checked]="contactSelection.isSelected(row)"
                                              [aria-label]="checkboxContactLabel(row)">
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <!-- Position Column -->
                        <ng-container matColumnDef="label">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef>Nom</th>
                            <td mat-cell *matCellDef="let contactLine"> {{contactLine.label}} </td>
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="email">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef>Mail</th>
                            <td mat-cell *matCellDef="let contactLine"> {{contactLine.email}} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="contactDisplayedColumns; sticky: true;"></tr>
                        <tr mat-row class="contactLine" [class.contactRecenltyAdded]="row.recentlyAdded"
                            *matRowDef="let row; columns: contactDisplayedColumns;">
                        </tr>
                    </table>
                </div>

            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="feMatTabTitle">
                        <span>Groupe de diffusion</span>
                    </div>
                </ng-template>

                <div class="searchContactsContainer">
                    <fe-searchbar placeHolder="Chercher un groupe..." (valueChange)="filterBroadcastChange($event)"/>
                </div>

                <div class="contactOptions">
                    <mat-checkbox (change)="$event ? toggleAllBroadcastRows() : null"
                                  [checked]="broadcastSelection.hasValue() && isAllBroadcastSelected()"
                                  [indeterminate]="broadcastSelection.hasValue() && !isAllBroadcastSelected()"
                                  [aria-label]="checkboxBroadcastLabel()">
                    </mat-checkbox>
                    <span *ngIf="broadcastSelection.hasValue()">
                      {{ getBroadcastsLabel() }}
                    </span>
                </div>

                <div class="contactsTableContainer" [class.displayNone]="loading">
                    <table mat-table [dataSource]="broadcastDataSource" matSort #contactLinesSort="matSort"
                           class="mat-elevation-z8">

                        <!-- Checkbox Column -->
                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                              (change)="$event ? broadcastSelection.toggle(row) : null"
                                              [checked]="broadcastSelection.isSelected(row)"
                                              [aria-label]="checkboxBroadcastLabel(row)">
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <!-- Position Column -->
                        <ng-container matColumnDef="label">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef>Nom</th>
                            <td mat-cell *matCellDef="let contactLine"> {{contactLine.label}} </td>
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="count">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef>Nombre de contacts</th>
                            <td mat-cell *matCellDef="let contactLine"> {{contactLine.mails.length}} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="broadcastDisplayedColumns; sticky: true;"></tr>
                        <tr mat-row class="contactLine" [class.contactRecenltyAdded]="row.recentlyAdded"
                            *matRowDef="let row; columns: broadcastDisplayedColumns;">
                        </tr>
                    </table>
                </div>
            </mat-tab>
        </mat-tab-group>
        <fe-loader class="feLoader" [class.displayNone]="!loading" loaderText="Chargement en cours..."/>
    </div>
    <div class="feModalFooter">
        <span style="margin: auto 0;">{{getResumeLabel()}}</span>
        <fe-button class="marginLeftAuto" [iconType]="IconType.check" icon="true"
                   text="Ajouter les destinataires sélectionnés à la newsletter" (click)="addContactsToNewsletter()"/>
    </div>
</div>
