<div class="scopePreviewNewsletterModal feModal">
    <div class="feModalHeader">
        <span class="feModalTitle">Prévisualisation</span>
        <div class="feModalCloseButton">
            <span mat-dialog-close>Fermer</span>
            <mat-icon mat-dialog-close>close</mat-icon>
        </div>
    </div>
    <div class="feModalContent">
        <div *ngIf="!isLoading" style="height: 660px;overflow-y: auto;margin: auto;" [innerHTML]="html">
        </div>
        <div *ngIf="isLoading" style="height: 660px">
            <fe-loader loaderText="Chargement en cours..."/>
        </div>
    </div>
    <div class="feModalFooter">
        <fe-button (click)="sendNewsletter()" text="Envoyer la newsletter"/>
    </div>
</div>
