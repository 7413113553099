import {Injectable} from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {FeModalComponent} from "../../components/fe-modal/fe-modal.component";
import {ComponentType} from '@angular/cdk/overlay';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private title: string = 'Titre par défaut';
  private text: string = 'Texte par défaut';
  private acceptBtnText: string = 'Accepter';
  private cancelBtnText: string = 'Annuler';
  private inputTitle: string = 'Titre par défaut';
  private inputHolder: string = 'Placeholder par défaut';
  private inputValue: string = 'Value par défaut';

  constructor(public dialog: MatDialog) { }

  public informationModal(title: string, text: string): void {
    this.title = title;
    this.text = text;
    const dialogRef = this.dialog.open(FeModalComponent, {
      data: {
        type: 'Information',
        title: this.title,
        text: this.text
      }});
  }

  public confirmModal(title: string, text: string, callbackFunction: (args: any) => void): void {
    const dialogRef = this.dialog.open(FeModalComponent, {
      data: {
        type: 'Confirm',
        title: title,
        text: text
      }});
    dialogRef.componentInstance.confirm.subscribe((response) => {
      callbackFunction(response);
    });
  }

  public inputModal(title: string, text: string, inputTitle: string, inputHolder: string, inputValue: string,
                    callbackFunction: (args: any) => void): void {
    this.title = title;
    this.text = text;
    if (inputTitle != null)
      this.inputTitle = inputTitle;
    if (inputHolder != null)
      this.inputHolder = inputHolder;
    if (inputValue != null)
      this.inputValue = inputValue;
    const dialogRef = this.dialog.open(FeModalComponent, {
      data: {
        type: 'Input',
        title: this.title,
        text: this.text,
        inputTitle: this.inputTitle,
        inputHolder: this.inputHolder,
        inputValue: this.inputValue
      }});
    dialogRef.componentInstance.confirm.subscribe((response) => {
      callbackFunction(response);
    });
  }

  public textAreaModal(title: string, text: string,
                    callbackFunction: (args: any) => void, textareaLabel?: string): void {
    this.title = title;
    this.text = text;
    const dialogRef = this.dialog.open(FeModalComponent, {
      data: {
        type: 'textarea',
        title: this.title,
        text: this.text,
        inputValue: '',
        textareaLabel: textareaLabel
      }});
    dialogRef.componentInstance.confirm.subscribe((response) => {
      callbackFunction(response);
    });
  }

  public showCustomModal(componant: ComponentType<any>, data: MatDialogConfig): MatDialogRef<any> {
    data.autoFocus = false;
    return this.dialog.open(componant, data);
  }

  public showCustomModalWithActionOnClose(componant: ComponentType<any>, data: MatDialogConfig,
                                          callbackFunction: (args: any) => void): void {
    data.autoFocus = false;
    const dialogRef = this.dialog.open(componant, data);
    dialogRef.componentInstance.confirm.subscribe((response) => {
      callbackFunction(response);
    });
  }

  public showCustomModalWithAlwaysActionOnClose(componant: ComponentType<any>, data: MatDialogConfig,
                                          callbackFunction: (args: any) => void): void {
    data.autoFocus = false;
    const dialogRef = this.dialog.open(componant, data);
    dialogRef.afterClosed().subscribe(response => {
      callbackFunction(response);
    });
  }
}
