import {Component, HostBinding, Inject, Input, Optional} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'fe-loader',
  templateUrl: './fe-loader.component.html',
  styleUrls: ['./fe-loader.component.scss']
})
export class FeLoaderComponent {
  @Input() loaderText: string = '';
  @Input() @HostBinding('class.isOverflowing') isOverflowing: boolean = false;
  @Input() noBackground: boolean = false;
  protected fromService: boolean = false;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data != null) {
      if (data.loaderText != null) {
        this.loaderText = data.loaderText;
      }

      if (data.fromService) {
        this.fromService = data.fromService;
      }
    }
  }
}
