<ion-header no-border>
    <ion-toolbar color="secondary">
        <div class="toolbar">
            <div style="display: flex; gap: 12px;">
                <button style="margin-left: 6px; width: 200px;" class="dropdownBtn"
                        [matMenuTriggerFor]="menuResultFilter" [matTooltip]="nlTitle"
                        (click)="onDropDownMenuOpenFiltre($event)">
                    <span style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                        {{nlTitle}}
                    </span>
                    <mat-icon style="margin-right: 0; min-width: 24px;">{{menuIconFiltre}}</mat-icon>
                </button>
                <fe-button text="Créer une newsletter" icon="true" smallButton="true" [iconType]="IconType.add"
                           style="margin: auto;" (click)="openCreateNewsletter()"/>
            </div>
            <!--      <mat-form-field class="input-amount mat-form-field" style="margin-left: 5px; flex: 1;" appearance="outline">-->
            <!--        <mat-label style="font-size:16px;">Newsletters</mat-label>-->
            <!--      </mat-form-field>-->

            <div style="display: flex; gap: 12px; margin-left: auto;">
                <fe-button text="Prévisualiser" icon="true" type="SECONDARY" [iconType]="IconType.eye_open"
                           (click)="previewNewsletter()" smallButton="true" style="margin: auto;"/>
                <fe-button text="Envoyer la newsletter" icon="true" [iconType]="IconType.send_fast_mail"
                           smallButton="true" style="margin: auto;" (click)="openSendNewsletter()"/>
                <button id="codeEditor" mat-icon-button title="Editeur" (click)="isNlSelected()"
                        style="width: 32px; height: 32px; overflow: hidden;">
                    <mat-icon class="outlined">code</mat-icon>
                </button>
                <button id="undoButton" mat-icon-button title="Annuler" (click)="isNlSelected()"
                        style="width: 32px; height: 32px; overflow: hidden;">
                    <mat-icon class="outlined">undo</mat-icon>
                </button>
                <button id="redoButton" mat-icon-button title="Rétablir" (click)="isNlSelected()"
                        style="width: 32px; height: 32px; overflow: hidden;">
                    <mat-icon class="outlined">redo</mat-icon>
                </button>
                <fe-button icon="true" title="Sauvegarder comme nouveau template" (click)="saveAsTemplate()"
                           [iconType]="IconType.save" hideText="true" type="TERTIARY"/>
                <fe-button icon="true" title="Historiser" (click)="historizeNewsletter()" [iconType]="IconType.archive"
                           hideText="true" type="TERTIARY"/>
            </div>


            <mat-menu class="menuTri" style="position: absolute" #menuResultFilter="matMenu" (close)="onMenuFiltreClosed()">
                <fe-menu-item *ngFor="let nl of newsletters" [text]="nl.name" [icon]="IconType.newsletter"
                              (click)="selectNewsletter(nl)"/>

            </mat-menu>
        </div>
    </ion-toolbar>
</ion-header>
<ion-content>
    <div style="height: 100%;">
        <!--Plugin containers -->
        <div #stripoSettingsContainer id="stripoSettingsContainer"></div>
        <div #stripoPreviewContainer id="stripoPreviewContainer"></div>

        <div *ngIf="noNlSelected && !isLoading" style="height: 100%; display: flex;">
            <div style="margin: auto; display: flex; gap: 16px; flex-direction: column;">
                <span style="font-size: 16px; font-weight: bold;">
                    Sélectionnez une newsletter ou créez en une.
                </span>
                <fe-button text="Créer une newsletter" style="margin: auto;"
                           (click)="openCreateNewsletter()"/>
            </div>
        </div>

        <fe-loader *ngIf="isLoading" loaderText="Chargement en cours..."/>
    </div>
</ion-content>
