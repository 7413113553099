import {Component, Input} from '@angular/core';

export enum IconType {
  creditInfo = 'credit_info',
  facebook = 'facebook',
  feArrow = 'fe_arrow',
  linkedin = 'linkedin',
  twitter = 'twitter',
  youtube = 'youtube',
  localisations = 'localisations',
  nature_locaux = 'nature_locaux',
  secteurs = 'secteurs',
  taille_entreprise = 'taille_entreprise',
  themes = 'themes',
  comment = 'comment',
  mots_cles = 'mots_cles',
  alerte = 'alerte',
  alerte_active = 'alerte_active',
  edit = 'edit',
  duplicate = 'duplicate',
  delete = 'delete',
  eye_open = 'eye_open',
  crown = 'crown',
  filter = 'filter',
  archive = 'archive',
  save = 'save',
  eye_off = 'eye_off',
  eye_close = 'eye_close',
  title = 'title',
  extrait = 'extrait',
  chat = 'chat',
  time = 'time',
  attachment = 'attachment',
  complet = 'complet',
  pertinence = 'pertinence',
  date_decroissante = 'date_decroissante',
  date_croissante = 'date_croissante',
  sorting_desc = 'sorting_desc',
  article = 'article',
  calendar = 'calendar',
  star = 'star',
  three_dots_hori = 'three_dots_hori',
  three_dots_vert = 'three_dots_vert',
  star_full = 'star_full',
  newsletter = 'newsletter',
  star_move = 'star_move',
  search = 'search',
  star_remove = 'star_remove',
  web_to_lead = 'web_to_lead',
  send_mail = 'send_mail',
  send_fast_mail = 'send_fast_mail',
  leads = 'leads',
  check = 'check',
  menu_open = 'menu_open',
  erase = 'erase',
  highlight = 'highlight',
  csv = 'csv',
  excel = 'excel',
  information = 'information',
  tag_theme = 'tag_theme',
  tag_secteur = 'tag_secteur',
  tag_localite = 'tag_localite',
  print = 'print',
  dashboard = 'dashboard',
  dashboard_add = 'dashboard_add',
  dashboard_full = 'dashboard_full',
  group = 'group',
  add_folder = 'add_folder',
  tour = 'tour',
  move_folder = 'move_folder',
  folder_open = 'folder_open',
  rename = 'rename',
  gear = 'gear',
  lock = 'lock',
  log_out = 'log_out',
  options = 'options',
  arrow_down = 'arrow_down',
  arrow_up = 'arrow_up',
  add = 'add',
  add_person = 'add_person',
  cup = 'cup',
  entreprise = 'entreprise'
}

export enum ColorType {
  red = 'red',
  blue = 'blue',
  lightBlack = 'lightBlack',
  darkGrey = 'darkGrey',
  grey = 'grey',
  green = 'green',
  white = 'white'
}

@Component({
  selector: 'fe-icon',
  templateUrl: './fe-icon.component.html',
  styleUrls: ['./fe-icon.component.scss']
})
export class FeIconComponent {
  @Input() iconType: IconType = IconType.creditInfo;
  @Input() color: ColorType = ColorType.blue;
  @Input() size: number = 20;
  protected readonly ColorType = ColorType;
}
