<div class="scopeSendNewsletter feModal">
    <div class="feModalHeader">
        <span class="feModalTitle">Envoyer une newsletter</span>
        <div class="feModalCloseButton">
            <span mat-dialog-close>Fermer</span>
            <mat-icon mat-dialog-close>close</mat-icon>
        </div>
    </div>
    <div class="feModalContent">
        <div class="shadowBloc">
            <div class="shadowBlocTitle">
                <span class="bigTextBold">Nom de la newsletter</span>
            </div>
            <div class="shadowBlocContent">
                <fe-input width="100%" [(value)]="nlName" placeHolder="Nom de la newsletter"/>
            </div>
        </div>
        <div class="shadowBloc">
            <div class="shadowBlocTitle">
                <span class="bigTextBold">Carnet d'adresses</span>
            </div>
            <div class="shadowBlocContent">
                <div class="searchDestinataireBloc">
                    <div class="feSearchBar">
                        <mat-icon>search</mat-icon>
                        <input placeholder="Rechercher un destinataire..." [formControl]="contactControl"
                               [matAutocomplete]="autoCompAddresses" [autofocus]="false">
                    </div>

                    <mat-autocomplete #autoCompAddresses="matAutocomplete" class="searchContactAutoComp">
                        <mat-option *ngFor="let contact of filteredContacts | async" class="contactOption"
                                    style="display: flex; padding: 12px !important; gap: 4px;"
                                    (click)="addContactToMail(contact)" [title]="contact.libelle">
                            <mat-icon *ngIf="contact.type == ScopeNewsletterContactLineType.mail"
                                      style="margin: 0;">person_outline</mat-icon>
                            <mat-icon *ngIf="contact.type != ScopeNewsletterContactLineType.mail"
                                      style="margin: 0;">people_outline</mat-icon>
                            <span class="contactName" [innerHtml]="getContactFormatLabel(contact)"></span>
                        </mat-option>
                    </mat-autocomplete>
                    <fe-button text="Carnet d'adresses" type="SECONDARY" bold="true" (click)="openScopeBookmark()"/>
                </div>
                <div class="destinatairesContainer">
                    <mat-chip-grid>
                        <mat-chip (removed)="removeContact(contact)" class="destinataireAdded"
                                  *ngFor="let contact of mailsList">
                            <div style="display: flex; gap: 4px;">
                                <mat-icon>person_outline</mat-icon>
                                <span [innerHTML]="getContactFormatLabel(contact)"
                                      style="margin: auto;"></span>
                            </div>
                            <mat-icon matChipRemove>close</mat-icon>
                        </mat-chip>
                        <mat-chip (removed)="removeContact(contact)" class="destinataireAdded"
                                  *ngFor="let contact of broadcastsList">
                            <div style="display: flex; gap: 4px;">
                                <mat-icon>people_outline</mat-icon>
                                <span [innerHTML]="getContactFormatLabel(contact)"
                                      style="margin: auto;"></span>
                            </div>

                            <mat-icon matChipRemove>close</mat-icon>
                        </mat-chip>
                    </mat-chip-grid>
                </div>
            </div>
        </div>
        <div class="shadowBloc">
            <div class="shadowBlocTitle">
                <fe-toggle [(value)]="showUnknownDestinataireContainer"/>
                <span class="bigTextBold">Ajouter un destinataire non présent dans la liste</span>
            </div>
            <div *ngIf="showUnknownDestinataireContainer" class="shadowBlocContent unknowDestinataires">
                <div class="unknowDestinataire" *ngFor="let unknowDestinataire of unknowDestinataires;">
                    <div class="unknowDestinataireText">
                        <fe-input (keydown)="onEditUnknownDestinataire(unknowDestinataire)"
                                  [(value)]="unknowDestinataire.data.email"
                                  placeHolder="Adresse email" width="350px"
                                  (focusout)="updateMailErrorMsg(unknowDestinataire)"/>
                        <fe-checkbox *ngIf="false" (valueChange)="unknowDestinataire.data.saveContact = $event"
                                     bold="true" text="Ajouter au carnet d’adresses"/>
                        <mat-icon *ngIf="isLastDestinataire(unknowDestinataire)"
                                  (click)="removeUnknownDestinataire(unknowDestinataire.addressId)">close</mat-icon>
                    </div>
                    <span *ngIf="unknowDestinataire?.haveErrors == true && isLastDestinataire(unknowDestinataire)"
                          class="moderateText dangerColor">
                        Le format de l'adresse mail n'est pas correct.
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="feModalFooter">
        <div class="getACopyContainer" *ngIf="false">
            <mat-checkbox [(ngModel)]="receiveACopy" class="fe-checkbox"/>
            <span (click)="receiveACopy = !receiveACopy" class="bigTextBold"
                  style="padding-top: 4px;">Recevoir une copie de l’email</span>
        </div>
        <span class="bigText marginLeftAuto">{{getResumeCountLabel()}}</span>
        <fe-button (click)="sendNewsletter()" text="Envoyer"/>
    </div>
</div>
