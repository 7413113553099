<div class="scopeCreateNewsletterModal feModal">
    <div class="feModalHeader">
        <span class="feModalTitle">Création d’une newsletter</span>
        <div class="feModalCloseButton">
            <span mat-dialog-close>Fermer</span>
            <mat-icon mat-dialog-close>close</mat-icon>
        </div>
    </div>
    <div class="feModalContent">
        <div class="shadowBloc">
            <div class="shadowBlocTitle">
                <span class="bigTextBold">Nom de la newsletter</span>
            </div>
            <div class="shadowBlocContent">
                <fe-input width="100%" [(value)]="newsletterName" placeHolder="Nom de la newsletter"/>
            </div>
        </div>

        <div style="display: flex; gap: 16px; overflow: hidden; flex: 1;">
            <div class="shadowBloc" style="flex-basis: 320px; display: flex; flex-direction: column">
                <div class="shadowBlocTitle">
                    <span class="bigTextBold">Mes dossiers</span>
                </div>
                <div class="shadowBlocContent" style="flex: 1; overflow: auto;">
                    <div class="favoriteListItems" *ngIf="!isLoadingFolder && scopeFolders.length > 0">
                        <scope-folder-item *ngFor="let scopeFolder of scopeFolders" [folderName]="scopeFolder.libelle"
                                           [folderCount]="scopeFolder.articles.length"
                                           [selectedFolder]="scopeFolder.folderID == selectedFolder?.folderID"
                                           (click)="selectFolder(scopeFolder)"/>
<!--                        <fe-loader *ngIf="isLoading" class="feLoader" loaderText="Chargement en cours..."/>-->
                    </div>
                    <span *ngIf="!isLoadingFolder && scopeFolders.length < 1" style="margin: auto">Aucun dossier</span>
                    <fe-loader loaderText="Chargement en cours..." class="feLoader"
                               *ngIf="isLoadingFolder"/>
                </div>
            </div>

            <div class="shadowBloc" style="flex: 1; display: flex; flex-direction: column;">
                <div class="shadowBlocTitle">
                    <span class="bigTextBold">Mes templates</span>
                </div>
                <div style="display: flex; flex-wrap: wrap; gap: 16px; padding: 8px 0; overflow: auto;"
                     *ngIf="!isLoadingTemplates">
                    <div class="shadowBloc newsletterIem" *ngFor="let newsletterTemplate of newsletterTemplates"
                         (click)="selectTemplate(newsletterTemplate)"
                         [class.selected]="newsletterTemplate.id == selectedTemplate?.id">
                        <span>{{newsletterTemplate.name}}</span>
                        <img src="/assets/images/newsletter_icon.jpg">
                    </div>
                </div>
                <span *ngIf="!isLoadingTemplates && newsletterTemplates.length < 1" style="margin: auto">Aucun template</span>
                <fe-loader loaderText="Chargement en cours..." class="feLoader"
                           *ngIf="isLoadingTemplates"/>
            </div>
        </div>
    </div>
    <div class="feModalFooter">
        <span class="marginLeftAuto" [innerHTML]="getResumeSelection()"></span>
        <fe-button (click)="createNewsletter()" text="Créer la newsletter"/>
    </div>
</div>
