import {Component, Inject} from '@angular/core';
import {AddressInterface} from '../../../interfaces/address/address';
import {FormControl} from '@angular/forms';
import {ModalService} from '../../../services/modal/modal.service';
import {
    ScopeBookAddressNewsletterComponent
} from '../scope-book-address-newsletter/scope-book-address-newsletter.component';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Newsletter} from "../../../interfaces/newsletter/newsletter";
import {Observable} from "rxjs";
import {ContactLineInterface} from "../fe-carnet-adresses-modal/fe-carnet-adresses-modal.component";
import {map, startWith} from "rxjs/operators";
import {ScopeMail, ScopeMailList} from "../../../interfaces/newsletter/ScopeMailList";
import {
    ScopeNewsletterContactLine,
    ScopeNewsletterContactLineType
} from "../../../interfaces/newsletter/ScopeNewsletterContactLine";
import {SnackbarService} from "../../../services/snackbar/snackbar.service";
import {MailsService} from "../../../services/mails/mails.service";
import {LoaderService} from "../../../services/loader/loader.service";
import {DomSanitizer} from "@angular/platform-browser";

declare function compileMail(func): any;

@Component({
  selector: 'scope-send-newsletter',
  templateUrl: './scope-send-newsletter.component.html',
  styleUrls: ['./scope-send-newsletter.component.scss']
})
export class ScopeSendNewsletterComponent {
    protected nlName: String;
    protected newsletter: Newsletter;
    protected receiveACopy: Boolean;
    protected showUnknownDestinataireContainer: Boolean;
    protected contactsArray: ScopeNewsletterContactLine[] = [];
    protected unknowDestinataires: AddressInterface[] = [{
        addressId: 0,
        data: {
            label: '',
            email: ''
        },
        haveErrors: false
    }];
    protected contactControl: FormControl<string> = new FormControl('');
    protected filteredContacts: Observable<ScopeNewsletterContactLine[]>;
    protected scopeNewsletterContactLines: ScopeNewsletterContactLine[] = [];
    protected broadcastsList: ScopeNewsletterContactLine[] = [];
    protected mailsList: ScopeNewsletterContactLine[] = [];
    private broadcastsRawList: ScopeMailList[];
    private mailsRawList: ScopeMail[];
    protected html: any;

    constructor(private modalService: ModalService,
                private snackbarService: SnackbarService,
                private mailsService: MailsService,
                private loaderService: LoaderService,
                private sanitizer: DomSanitizer,
                protected dialogRef: MatDialogRef<ScopeSendNewsletterComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        if (data != null) {
            if (data.newsletter != null) {
                this.newsletter = data.newsletter;
                this.nlName = data.newsletter.name;
            }
            if (data.mailList != null) {
                data.mailList.forEach(value => {
                    this.scopeNewsletterContactLines.push({id: value.id,
                        type: ScopeNewsletterContactLineType.mail, libelle: value.name, mail: value.email});
                });
                this.mailsRawList = data.mailList;
            }
            if (data.broadcastList != null) {
                data.broadcastList.forEach(value => {
                    this.scopeNewsletterContactLines.push({id: value.id,
                        type: ScopeNewsletterContactLineType.broadcast, libelle: value.libelle, mails: value.mails});
                });
                this.broadcastsRawList = data.broadcastList;
            }
            this.filteredContacts = this.contactControl.valueChanges.pipe(
                startWith(''),
                map(value => this._filterContact(value || '')),
            );
        }
    }

    protected openScopeBookmark(): void {
        this.modalService.showCustomModalWithActionOnClose(ScopeBookAddressNewsletterComponent,
            {data: {broadcastsList: this.broadcastsRawList, mailsList: this.mailsRawList}}, (response) => {
                if (response != null) {
                    response.contacts.forEach(contact => {
                       this.insertContact(contact);
                    });
                    response.broadcasts.forEach(broadcast => {
                       this.insertContact(broadcast);
                    });
                }});
    }

    protected sendNewsletter(): void {
        if (this.getContactCount() > 0) {
            const unknownAdressWithErrors: AddressInterface[] = new Array<AddressInterface>();

            if (this.showUnknownDestinataireContainer) {
                this.unknowDestinataires.forEach(value => {
                    if (value.haveErrors && value.data.email.trim().length > 0) {
                        unknownAdressWithErrors.push(value);
                    }
                });
            }

            if (unknownAdressWithErrors.length < 1) {
                this.modalService.confirmModal('Envoi de la newsletter',
                    'Voulez vous vraiment envoyer la newsletter "<b>' + this.nlName + '</b>" maintenant ?',
                        args => {
                    this.loadHtmlAndSendNewsletter();
                });
            } else {
                let message: string = 'Les adresses suivantes ne sont pas conformes : ';
                unknownAdressWithErrors.forEach(value => {
                    message += '<br>- ' + value.data.email;
                });
                this.snackbarService.showDangerSnackbar('Opération impossible', message);
            }
        } else {
            this.snackbarService.showDangerSnackbar('Opération impossible',
                'Vous n\'avez ajouté aucun destinataire à votre envoi.');
        }
    }

    private getAllMails(): String[] {
        let mailsToReturn: String[] = [];

        if (this.showUnknownDestinataireContainer) {
            this.unknowDestinataires.forEach(value => {
                if (value.data.email.trim().length > 0) {
                    mailsToReturn.push(value.data.email);
                }
            });
        }

        if (this.broadcastsList != null) {
            this.broadcastsList.forEach(broadcast => {
                broadcast.mails.forEach(mail => {
                    mailsToReturn.push(mail.email);
                })
            });
        }

        if (this.mailsList != null) {
            this.mailsList.forEach(mail => {
                mailsToReturn.push(mail.mail);
            });
        }

        return mailsToReturn;
    }



    protected getResumeCountLabel(): String {
        let resumeLabel : string = this.getContactCount() + ' destinataire';
        if (this.getContactCount() > 1) {
            resumeLabel += 's';
        }
        return resumeLabel;
    }

    protected getContactCount(): number {
        let count: number = this.contactsArray.length;
        if (this.showUnknownDestinataireContainer) {
            this.unknowDestinataires.forEach(value => {
                if (value.data.email.trim().length > 0) {
                    count ++;
                }
            });
        }

        if (this.broadcastsList != null) {
            this.broadcastsList.forEach(broadcast => {
                count += broadcast.mails.length;
            });
        }

        if (this.mailsList != null) {
            count += this.mailsList.length;
        }

        return count;
    }

    protected onEditUnknownDestinataire(unknowDestinataire: AddressInterface): void {
        if (this.unknowDestinataires[this.unknowDestinataires.length - 1].addressId == unknowDestinataire.addressId) {
            this.unknowDestinataires.push({addressId: unknowDestinataire.addressId + 1, data: {label: '', email: ''}})
        }
        this.updateMailErrorMsg(unknowDestinataire);
    }

    protected updateMailErrorMsg(unknowDestinataire: AddressInterface): void {
        unknowDestinataire.haveErrors = !this.isValidEmail(unknowDestinataire.data.email);
    }

    protected isValidEmail(email: string): boolean {
        const emailRegex: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return email.trim().length < 1 || emailRegex.test(email);
    }

    protected isLastDestinataire(unknowDestinataire: AddressInterface): Boolean {
        return this.unknowDestinataires[this.unknowDestinataires.length - 1].addressId != unknowDestinataire.addressId;
    }

    protected removeUnknownDestinataire(addressId: number): void {
        this.unknowDestinataires.splice(this.unknowDestinataires.map(e => e.addressId)
            .indexOf(addressId), 1);
    }

    protected removeContact(contact: ScopeNewsletterContactLine): void {
        if (contact.type == ScopeNewsletterContactLineType.mail) {
            this.mailsList.splice(this.mailsList.map(e => e.id)
                .indexOf(contact.id), 1);
        } else {
            this.broadcastsList.splice(this.broadcastsList.map(e => e.id)
                .indexOf(contact.id), 1);
        }

    }

    protected addContactToMail(contact: ScopeNewsletterContactLine): void {
        this.insertContact(contact);
    }

    private insertContact(contact: ScopeNewsletterContactLine):void {
        let notPresent: boolean = true;
        if (contact.type == ScopeNewsletterContactLineType.mail) {
            this.mailsList.forEach(value => {
                if (value.id == contact.id) {
                    notPresent = false;
                }
            });
            if (notPresent) {
                this.mailsList.push(contact);
            }
        } else {
            this.broadcastsList.forEach(value => {
                if (value.id == contact.id) {
                    notPresent = false;
                }
            });
            if (notPresent) {
                this.broadcastsList.push(contact);
            }
        }

    }

    protected getContactTitle(contact: ContactLineInterface): string {
        let contactLabel: string = '';
        if (contact.label && contact.label.trim().length > 0) {
            contactLabel = contact.label + ' (' + contact.email + ')';
        } else {
            contactLabel = contact.email;
        }
        return contactLabel;
    }

    protected getContactFormatLabel(contact: ScopeNewsletterContactLine): string {
        let contactLabel: string = '';
        if (contact.type == ScopeNewsletterContactLineType.mail) {
            if (contact.libelle && contact.libelle.trim().length > 0 && contact.mail != null) {
                contactLabel = '<span class="bold">' + contact.libelle + '</span> (' + contact.mail + ')';
            } else {
                contactLabel = contact.mail;
            }
        } else {
            contactLabel = '<span class="bold">' + contact.libelle + '</span> (' + contact.mails.length +
                (contact.mails.length > 1 ? ' contacts)': ' contact)');
        }
        return contactLabel;
    }

    private _filterContact(value: string): ScopeNewsletterContactLine[] {
        const filterValue = value.toLowerCase();

        return this.scopeNewsletterContactLines.filter(option =>
            option.libelle.toLowerCase().includes(filterValue));
    }

    protected readonly ScopeNewsletterContactLineType = ScopeNewsletterContactLineType;

    private loadHtmlAndSendNewsletter(): void {
        this.loaderService.showLoader(true, 'Envoi en cours...');
        const truc = this;
        compileMail(function() {
            truc.showPreviewBridge((window as any).htmlcss);
        });
    }

    private showPreviewBridge(htmlCss: any): void {
        this.showPreview(htmlCss);
    }

    private showPreview(htmlCss: any) {
        this.mailsService.nl = this.newsletter;
        this.mailsService.nl.html = htmlCss.html;

        this.mailsService.sendNewsletterScope(this.newsletter.id, this.mailsService.nl.html,
            this.nlName, this.getAllMails()).subscribe(value => {
            this.loaderService.showLoader(false, '');
            this.snackbarService.showSuccessSnackbar('Envoi réussi',
                'La newsletter "<b>' + this.nlName + '</b>" a été envoyée avec succès.');
            this.dialogRef.close();
        });
    }


}
