import {Component, OnInit, OnDestroy, Input, ViewEncapsulation, ViewChild} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {map, share, takeUntil} from 'rxjs/operators';
import { MediaQueryService } from 'src/app/services/media-query/media-query.service';
import { Router, NavigationEnd } from '@angular/router';
import {AlertController, ModalController, NavController} from '@ionic/angular';
import { MatSidenav } from '@angular/material/sidenav';
import {Utils} from '../../classes/Utils';
import {UserService} from '../../services/user/user.service';
import {SessionInterface} from '../../interfaces/session/session.interface';
import {HttpResponse} from '@angular/common/http';
import {ApiService} from '../../services/api/api.service';
import {MySearchesAddComponent} from '../my-searches/my-searches-add/my-searches-add.component';
import {SearchInterface} from '../../interfaces/search/search.interface';
import {SearchesInterface} from '../../interfaces/searches/searches.interface';
import {SearchesService} from '../../services/searches/searches.service';
import {FavorisFolder} from '../../interfaces/favoritesFolders/favorites-folders.interface';
import {FavoritesInterface} from '../../interfaces/favorites/favorites.interface';
import {FavoritesService} from '../../services/favorites/favorites.service';
import {NavigationOptions} from '@ionic/angular/common/providers/nav-controller';
import {CreateLeadModalComponent} from '../modals/create-lead-modal/create-lead-modal.component';
import {ManageSearchesModalComponent} from '../modals/manage-searches-modal/manage-searches-modal.component';
import {MatMenu, MatMenuTrigger} from '@angular/material/menu';
import {ErrorStatus} from '../../classes/ErrorStatus.class';
import {ToastService} from '../../services/toast/toast.service';
import {SearchDataInterface} from '../../interfaces/search/search-data.interface';
import {SearchRecentDataInterface} from '../../interfaces/search/search-recent-data.interface';
import {SearchCreationComponent} from '../my-searches/search-creation/search-creation.component';
import {MatDialog} from '@angular/material/dialog';
import {ModalService} from "../../services/modal/modal.service";
import {
  FeGestionRecherchesModalComponent
} from "../modals/fe-gestion-recherches-modal/fe-gestion-recherches-modal.component";
import {SendingHistoryDetailModal} from "../modals/sending-history-detail/sending-history-detail.modal";
import {FeCarnetAdressesModalComponent} from "../modals/fe-carnet-adresses-modal/fe-carnet-adresses-modal.component";
import {IconType} from "../parts/fe-icon/fe-icon.component";
import {SnackbarService} from "../../services/snackbar/snackbar.service";
import {GlobalService} from '../../services/global/global.service';
import {FeSearchbarComponent} from '../parts/fe-searchbar/fe-searchbar.component';
import {MailsService} from "../../services/mails/mails.service";
import {ShareSearchesComponent} from '../modals/share-searches/share-searches.component';
import {AuthService} from '../../services/auth/auth.service';
import {
  FeContactCommercialModalComponent
} from "../modals/fe-contact-commercial-modal/fe-contact-commercial-modal.component";

@Component({
  selector: 'main-toolbar',
  templateUrl: 'main-toolbar.component.html',
  styleUrls: ['main-toolbar.component.scss'],
})
export class MainToolbarComponent implements OnInit, OnDestroy {

  @Input() sideNav: MatSidenav;
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  @ViewChild('feSearchBarFavoris') feSearchBarFavoris: FeSearchbarComponent;
  @ViewChild('feSearchBarBusiness') feSearchBarBusiness: FeSearchbarComponent;

  private unsubscribe$: Subject<any> = new Subject<any>();

  public showUserInset: boolean = true;
  public showMenuButton: boolean = true;
  public showTitle: boolean = true;
  public showButtonClose: boolean = false;
  public userToken: string = '';
  public sessionToken: string = '';
  public menuItems: Array<any> = [];
  public monitoringLinkAvailable: boolean = true;
  public selectedButton: string ;
  public menuOpenBusinessSignals: boolean = false;
  public menuOpenFavorites: boolean = false;
  public menuOpenSharedSignals: boolean = false;
  public menuIconBusinessSignals: string = 'keyboard_arrow_down';
  public menuIconFavorites: string = 'keyboard_arrow_down';
  public menuIconSharedSignals: string = 'keyboard_arrow_down';
  public mySearchList: SearchInterface[];
  public myFavoritesList: FavorisFolder[];
  @Input() public sessionData: SessionInterface;
  public currentSearch: SearchInterface;
  public currentSearchId: number;
  public currentIndex: number;
  public displayChips: boolean = false;
  public filter: string;


  constructor(
    public mediaQueryService: MediaQueryService
    , private navController: NavController
    , private router: Router
    , private userService: UserService
    , private apiService: ApiService
    , public mySearchesService: SearchesService
    , private favoritesService: FavoritesService
    , private modalController: ModalController
    , private alertController: AlertController
    , private toastService: ToastService
    , public dialog: MatDialog
    , private modalService: ModalService
    , private snackBarService: SnackbarService
    , private globalService: GlobalService
    , private mailService: MailsService
    , public authService: AuthService
  ) {
    this.menuItems = [
      {
        label: 'Signaux business',
        name: 'businessSignals',
        firstEcoIcon: 'icon-first-eco-arrow',
        uri: '/app/business-signals',
        matIcon: 'keyboard_arrow_down',
        direction: 'forward'
      },
      {
        label: 'Suivi d\'affaires',
        // id: 'ARTICLES_MONITORING',
        name: 'monitoring',
        available: this.monitoringLinkAvailable,
        class: 'outlined',
        uri: '/app/monitoring-articles',
        direction: 'forward'
      },
      {
        label: 'Favoris',
        name: 'myFavorites',
        matIcon: 'keyboard_arrow_down',
        class: 'outlined',
        uri: '/app/my-favorites',
        direction: 'forward'
      },
      {
        label: 'Signaux partagés',
        name: 'sharedSignals',
        class: 'outlined',
        matIcon: 'keyboard_arrow_down',
        uri: '/app/sending-history',
        direction: 'forward'
      },
      // {
      //   label: 'Newsletter',
      //   matIcon: 'markunread_mailbox',
      //   class: 'outlined',
      //   uri: '/app/newsletter',
      //   direction: 'forward'
      // }
    ];
    this.userService.getSessionDatas().pipe(
    ).subscribe((sessionData: SessionInterface) => {
      if (sessionData !== undefined) {
        if (sessionData.customerDetails.hasNewsletterAccess) {
          this.menuItems.push({
            label: 'Newsletter',
            matIcon: 'markunread_mailbox',
            class: 'outlined',
            uri: '/app/newsletter',
            direction: 'forward'
          });
        }
      }
    });

    this.globalService.menuLink$.subscribe(menuName => {
      this.setMenuItem(menuName);
    });
  }

  private setMenuItem(menuName: string): void  {
    let i: number = 0;
    this.menuItems.forEach(menuItem => {
      if (menuItem.name == menuName) {
        this.selectedButton = this.menuItems[i];
        return;
      }
      i++;
    });
  }

  public ngOnInit(): void {
    this.selectedButton = this.menuItems[0];
    this.getMySearches();
    this.getMyFavorites();
    this.mySearchesService.refreshSearchId.subscribe((searchId: number) => {
      // try {
        this.currentSearchId = this.mySearchList[searchId].searchId;
      // } catch (e) {
      // }
    });
    // listener for route change
    this.router.events.pipe(
      takeUntil(this.unsubscribe$)
    )
    .subscribe((events: any) => {

      if (events instanceof NavigationEnd) {
        if (!this.mediaQueryService.mobileQuery.matches) {
          return;
        }

        // we keep just url without parameter. We delete ?foo=bar&etc=etc...
        const urlWithoutParams: string = events.urlAfterRedirects.split('?')[0];

        if (urlWithoutParams === '/app/business-signals') {
          this.showUserInset = true;
          this.showMenuButton = true;
          this.showTitle = false;
          this.showButtonClose = false;
        } else {
          /*this.showFirstEcoLogo = false;
          this.showUserInset = false;
          this.showMenuButton = false;
          this.showTitle = true;
          this.showButtonClose = true;*/
          this.showUserInset = true;
          this.showMenuButton = true;
          this.showTitle = false;
          this.showButtonClose = false;
        }
      }
    });
  this.userToken = Utils.userToken;
  this.getSessionToken();
  }

  public navigateToUrl(url: string, direction: string = 'forward'): void {
    if (typeof url !== 'undefined' && url !== null && url !== '') {
      if (direction === 'forward') {
        Utils.navigate(url, this.navController);
        // this.navController.navigateForward(url);
      } else if (direction === 'back') {
        this.navController.navigateBack(url);
      } else if (direction === 'root') {
        this.navController.navigateRoot(url);
      }
    }
  }

  public getSessionToken() {
    this.userService.getSessionDatas().pipe(
    ).subscribe((sessionData: SessionInterface) => {
          if (sessionData !== undefined) {
            this.sessionData = sessionData;
            // this.sessionData.isScope = true;
            sessionData.appConfig.viewConfigs.forEach(value => {
              if (value.component === 'SUIVI_AFFAIRES') {
                if (value.state !== 'visible') {
                  this.monitoringLinkAvailable = false;
                  this.menuItems[1].available = false;
                }
              }
            });

            // this.sessionData.productType = 'Lettre_MotsCles';
            if (this.sessionData.productType === 'Lettre_MotsCles') {
              this.menuItems = [
                {
                  label: 'Mes signaux business',
                  name: 'businessSignals',
                  firstEcoIcon: 'icon-first-eco-arrow',
                  uri: '/app/business-signals',
                  matIcon: 'keyboard_arrow_down',
                  direction: 'back'
                }
              ];
            }
          }
        });
  }

  public trackByMenuItem(index: number, item) {
    return item.url;
  }

  onMenuClick(item: any) {
    this.selectedButton = item;

    if (item.name === 'sharedSignals') {
      this.navigateToUrl(item.uri, item.direction);
      this.userService.log('READ_MAIL_HISTORY');
      // this.modalService.showCustomModal(SendingHistoryDetailModal, {});
    }
    else {
      this.navigateToUrl(item.uri, item.direction);
      this.userService.log('NAVIGATION_TAB_DISPLAY');
    }
    // if (item.id === 'ARTICLES_MONITORING' && item.available != null && !item.available) {
    //
    // }
  }


  onMenuBusinessSignalsClosed() {
    this.menuOpenBusinessSignals = false;
    this.menuIconBusinessSignals = 'keyboard_arrow_down';
    this.filter = '';
    this.feSearchBarBusiness.clear();
  }

  onMenuFavoritesClosed() {
    this.menuOpenFavorites = false;
    this.menuIconFavorites = 'keyboard_arrow_down';
    this.filter = '';
    this.feSearchBarFavoris.clear();
  }

  onMenuSharedSignalsClosed() {
    this.menuOpenSharedSignals = false;
    this.menuIconSharedSignals = 'keyboard_arrow_down';
  }

  protected openCarnetAdresses(): void {
    this.modalService.showCustomModal(FeCarnetAdressesModalComponent, {});
  }

  isButtonSelected(button: string) {
    return button === this.selectedButton;
  }

  onDropDownMenuOpenBusinessSignals($event) {
    $event.stopPropagation();
    this.menuOpenBusinessSignals = !this.menuOpenBusinessSignals;
    if (this.menuOpenBusinessSignals) {
      this.menuIconBusinessSignals = 'keyboard_arrow_up';
    }
  }

  onDropDownMenuOpenFavorites($event) {
    $event.stopPropagation();
    this.menuOpenFavorites = !this.menuOpenFavorites;
    if (this.menuOpenFavorites) {
      this.menuIconFavorites = 'keyboard_arrow_up';
    }
  }

  onDropDownMenuOpenSharedSignals($event) {
    $event.stopPropagation();
    this.menuOpenSharedSignals = !this.menuOpenSharedSignals;
    if (this.menuOpenSharedSignals) {
      this.menuIconSharedSignals = 'keyboard_arrow_up';
    }
  }


  public getMySearches(loadLastSearch?: boolean): void {

    this.mySearchesService.getMySearches().subscribe(
            (mySearches: SearchesInterface) => {
              this.mySearchList = mySearches.searches;
              if (loadLastSearch) {
                this.mySearchesService.emitEventToBusinessSignals(mySearches.searches[mySearches.searches.length - 1].searchId);
              }
              this.mySearchesService.mySearchList$.subscribe(value => {
                this.mySearchList = value;
              });
            }
        );
  }

  public getMyFavorites(): void {
    this.favoritesService.getFavoritesFolders().subscribe((data: Array<FavorisFolder>) => {
      this.myFavoritesList = data;
    });
  }

  openBusinessSignals(search: SearchInterface, index: number) {
    search.dataRecentResults.isView = true;
    this.emitEventBusinessSignals(search.searchId);
    this.setMenuItem('businessSignals');

    const params: NavigationOptions = {
      queryParams: {
        search: search.searchId
      }};

    Utils.navigate('/app/business-signals', this.navController, params);
    // this.navController.navigateForward('/app/business-signals', params);
    this.menuTrigger.closeMenu();
  }

  openFavorites(search: FavorisFolder, index: number) {
    this.emitEventFavorites(index);

    const params: NavigationOptions = {
      queryParams: {
        folderID: search.folderID
      }
    };
    Utils.navigate('/app/my-favorites', this.navController, params);
    // this.navController.navigateForward('/app/business-signals', params);
  }


  emitEventBusinessSignals(index: number) {
    this.mySearchesService.emitEventToBusinessSignals(index);
  }

  emitEventFavorites(index: number) {
    this.mySearchesService.emitEventToFavorites(index);
  }

  sidenavToggle(sidenav: MatSidenav) {
    sidenav.toggle();
  }

  // public async openManageSearchesModal(): Promise<void> {
  //   const modal = await this.modalController.create({
  //     component: ManageSearchesModalComponent,
  //     cssClass: this.mediaQueryService.mobileQuery.matches ? 'mobileDialog' : 'manageSearchesModal',
  //     componentProps: {
  //       searchList: this.mySearchList
  //     },
  //     backdropDismiss: true
  //   });
  //   modal.onDidDismiss()
  //       .then(
  //           (data: any) => {
  //
  //           }
  //       );
  //   return await modal.present();
  // }

  protected openManageSearchesModal(): void {
    // this.modalService.inputModal('', '', '', '', args => {
    //
    // });
    this.modalService.showCustomModal(FeGestionRecherchesModalComponent, {});
  }

  public ngOnDestroy() {
    // this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  updateCurrentSearch(search: SearchInterface, index: number, $event) {
    $event.stopPropagation();
    this.currentSearch = search;
    this.currentSearchId = search.searchId;
    this.currentIndex = index;
  }
  public async askBeforeDuplicateSearch(search: SearchInterface): Promise<any> {
    const alert = await this.alertController.create({
      header: 'Attention',
      message: '<b>Voulez-vous vraiment dupliquer cette recherche ? </b>',
      buttons: [
        {
          text: 'Annuler',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: 'Oui',
          handler: () => this.duplicateSearch(search.searchId)
        }
      ]
    });
    await alert.present();
  }

  private duplicateSearch(id: number): void {
    this.mySearchesService.duplicateSearchById(id).pipe(
        takeUntil(this.unsubscribe$)
    ).subscribe(
        () => {
          this.toastService.simple('Recherche dupliquée avec succès');
          const newSearch: SearchInterface = {
            readOnly: this.mySearchList[this.currentIndex].readOnly,
            data: this.mySearchList[this.currentIndex].data,
            dataRecentResults: this.mySearchList[this.currentIndex].dataRecentResults
          };
          newSearch.data.label += ' (copie)';
          this.mySearchList.push(newSearch);
        },
        (error: Error | ErrorStatus) => {
          let errorStr: string = 'Une erreur est survenue lors de la duplication de cette recherche';
          // if (typeof error !== 'undefined' && typeof error.message !== 'undefined' && error.message !== null && error.message !== '') {
          //   errorStr += `(${this.translateService.instant(error.message)})`;
          // }
          this.toastService.simple(errorStr, {color: 'toasterror'});
        }
    );
  }

  async confirmRenameSearch(currentSearchId: number, currentIndex: number) {
    const alert = await this.alertController.create({
      message: 'Entrez le nouveau nom de la recherche',
      inputs : [
        {
          name: 'libelle',
          placeholder: 'Nouveau nom'
        },
      ],
      buttons: [
        {
          text: 'Annuler',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => { }
        }, {
          text: 'VALIDER',
          handler: (data => {
            this.renameSearch(currentSearchId, currentIndex, data.libelle);
          })
        }
      ]
    });
    await alert.present();
  }

  renameSearch(currentSearchId: number, currentIndex: number, libelle: string) {
    this.mySearchesService.renameSearchById(currentSearchId, libelle).pipe(
        takeUntil(this.unsubscribe$)
    ).subscribe(
        (renamedSearch) => {
          this.mySearchList[currentIndex] = renamedSearch;
        },
        (error: Error | ErrorStatus) => {
          let errorStr: string = 'Une erreur est survenue lors du renommage de cette recherche';
          // if (typeof error !== 'undefined' && typeof error.message !== 'undefined' && error.message !== null && error.message !== '') {
          //   errorStr += `(${this.translateService.instant(error.message)})`;
          // }
          this.toastService.simple(errorStr, {color: 'toasterror'});
        }
    );
  }

  public async askBeforeRemoveSearch(search: SearchInterface, currentIndex: number): Promise<any> {
    const alert = await this.alertController.create({
      header: 'Attention',
      message: '<b>Voulez-vous vraiment supprimer cette recherche ? </b>',
      buttons: [
        {
          text: 'Annuler',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: 'Oui',
          handler: () => this.removeSearch(search.searchId, currentIndex)
        }
      ]
    });
    await alert.present();
  }

  private removeSearch(id: number, currentIndex: number): void {
    this.mySearchesService.removeSearchById(id).pipe(
        takeUntil(this.unsubscribe$)
    ).subscribe(
        () => {
          this.toastService.simple('Recherche supprimée avec succès');
          this.mySearchList.splice(currentIndex, 1);
        },
        (error: Error | ErrorStatus) => {
          let errorStr: string = 'Une erreur est survenue lors de la suppression de cette recherche';
          // if (typeof error !== 'undefined' && typeof error.message !== 'undefined' && error.message !== null && error.message !== '') {
          //   errorStr += `(${this.translateService.instant(error.message)})`;
          // }
          this.toastService.simple(errorStr, {color: 'toasterror'});
        }
    );
  }

  public toggleNotifications($event): void {
    $event.stopPropagation();
    this.currentSearch.data.alertState = !this.currentSearch.data.alertState;

    this.mySearchesService.editMySearch(this.currentSearch.searchId, this.currentSearch.data).subscribe(
        (editedSearch: SearchInterface) => {
        }
    );
  }

  isToolTipActive(i: number): boolean {
    this.displayChips = false;
    const nbResults: number = this.mySearchList[i].dataRecentResults.nbResults;
    const isView: boolean = this.mySearchList[i].dataRecentResults.isView;
    if (isView !== undefined && !isView && nbResults !== undefined) {
      this.displayChips = true;
    }
    return !this.displayChips;
  }

  getToolTipLabel(i: number) {
    const nbResults: number = this.mySearchList[i].dataRecentResults.nbResults;
    if (this.displayChips) {
      return nbResults;
    }
    return undefined;
  }

   async createNewSearch() {
    // Utils.navigate('/app/my-searches-add', this.navController);

    const dial = this.dialog.open(SearchCreationComponent, {disableClose: true, height: 'calc(100% - 73px)', width: '100%', maxWidth: '100%', position: {bottom: '0', top: '73px', right: '0', left: '0'},
      hasBackdrop: true});
    dial.afterClosed().subscribe(value => {
      if (value) {
        this.getMySearches(true);
      }
    });
    // const modal = await this.modalController.create({
    //   component: SearchCreationComponent,
    //   cssClass: 'searchModal',
    //   showBackdrop: false,
    //   // componentProps: {
    //   //   searchList: this.mySearchList
    //   // },
    //   // backdropDismiss: true
    // });
    // modal.onDidDismiss()
    //     .then(
    //         (data: any) => {
    //
    //         }
    //     );
    // return await modal.present();
  }

  protected readonly event = event;
  protected readonly IconType = IconType;

    filterChange($event: string) {
        this.filter = $event;
    }

  protected loadFolder(folder: FavorisFolder): void {
      this.selectedButton = this.menuItems[2];

      const params: NavigationOptions = {
        queryParams: {
          folderID: folder.folderID
        }
      };
      Utils.navigate('/app/my-favorites', this.navController, params);
  }

  protected renameFolder(index: number): void {
    if (index === 0) {
      this.snackBarService.showDangerSnackbar('Opération impossible', 'Vous ne pouvez pas renommer le dossier par défaut.');
    } else {
      const oldTitle = this.myFavoritesList[index].libelle;
      this.modalService.inputModal('Renommer le dossier', 'Indiquer un nouveau nom au dossier \'' + oldTitle + '\'.',
          '', '', oldTitle, args => {
            if (!args || args.length <= 0) {
              this.snackBarService.showDangerSnackbar('Renommer le dossier', 'Votre dossier doit avoir un nom pour pouvoir être renommée.');
            }
            else {
              this.myFavoritesList[index].libelle = args;
              this.favoritesService.editFavoritesFolder(this.myFavoritesList[index].folderID, this.myFavoritesList[index])
                  .subscribe(value => {
                    this.snackBarService.showSuccessSnackbar('Opération réussie', 'Le dossier \'' + oldTitle
                        + '\' a bien été renommé en \'' + args + '\'');
                  });
            }
        });
    }
  }

  protected createFolder(): void {
    this.modalService.inputModal('Création d\'un nouveau dossier', 'Indiquer un nom au nouveau dossier.',
        '', '', '', args => {
          if (!args || args.length <= 0) {
            this.snackBarService.showDangerSnackbar('Création d\'un nouveau dossier', 'Vous devez indiquer un nom au dossier à créer.');
          }
          else {
            const folder: FavorisFolder = {
              libelle: args
            };
            this.favoritesService.addFavoritesFolder(folder).subscribe(value => {
              this.favoritesService.getFavoritesFolders();
              this.favoritesService.needRefresh.next(true);
              this.snackBarService.showSuccessSnackbar('Opération réussie', 'Le dossier \''
                  + this.getFavoriteFolderLink(value.body.folderID, value.body.libelle) + '\' a été créé avec succès.');
            });
          }
      });
  }

  private getFavoriteFolderLink(folderId: number, folderLabel: string): string {
    return '<b><a class="greenC" href="/#/app/my-favorites?folderID='
        + folderId + '">' + this.troncateText(folderLabel, 40) + '</a></b>';
  }

  private troncateText(text: string, length: number): string {
    let troncateText: string = text;
    if (troncateText.length > length + 2) {
      troncateText = troncateText.substring(0, length) + '...';
    }
    return troncateText;
  }

  protected removeFolder(index: number): void {
    if (index === 0) {
      this.snackBarService.showDangerSnackbar('Opération impossible', 'Vous ne pouvez pas supprimer le dossier par défaut.');
    } else {
      const folderTitle: string = this.myFavoritesList[index].libelle;
      let message: string = 'Attention, vous allez supprimer le dossier \'' + folderTitle + '\'.';
      if (this.myFavoritesList[index].folderArticlesCount > 1) {
        message += '<br>Les articles dans ce dossier seront également retirés des favoris.';
      } else if (this.myFavoritesList[index].folderArticlesCount > 0) {
        message += '<br>L\'article dans ce dossier sera également retiré des favoris.';
      }
      this.modalService.confirmModal('Retrait du dossier', message, args => {
        this.favoritesService.deleteFavoritesFolder(this.myFavoritesList[index].folderID).subscribe(value => {
          // if (this.myFavoritesList[index].folderID === this.currentFolderId) {
          //   this.loadFolderArticles(0); // TODO Gérer si on est sur le dossier courant
          // }
          this.snackBarService.showSuccessSnackbar('Dossier retiré avec succès', 'le dossier <b>\''
              + folderTitle + '\'</b> a été retiré avec succès.');
        });
      });
    }
  }

  protected showFunctionDisabled(name: string): void {
      let text = '';
      let titleAskContactModal = '';
      switch (name) {
        case 'monitoring':
          text = 'La fonctionnalité "Suivi d\'affaires" vous permet de suivre les ' +
              'actions commerciales de vos équipes. Si vous souhaitez en savoir plus, ' +
              'merci de contacter votre chargé d\'affaires.';
          titleAskContactModal = 'Vous souhaitez des renseignements sur Suivi d\'affaires ?';
          break;
        default:
          text = 'Cette fonctionnalité n\'est pas disponible avec votre offre actuelle.';
          break;
      }
      this.snackBarService.showDangerSnackbar('Fonctionnalité indisponible', text, 15, args => {
        this.modalService.showCustomModal(FeContactCommercialModalComponent, {
          data : {
            salesPersonDetails: this.userService.sessionData.salesPersonDetails,
            feature: name,
            title: titleAskContactModal
          }
        })},'Contacter mon conseiller');
  }

  protected openHistory(): void {
    this.navigateToUrl('/app/sending-history', 'forward');
    this.globalService.updateMenuLink('sharedSignals');
  }

  public getNotificationsCount(): number {
    if(this.sessionData.isScope) {
      return 0;
    }
    return this.userService.sessionData.monitoringNotifications.newAttachmentsCount +
        this.userService.sessionData.monitoringNotifications.newCommentsCount;

  }

  openDuplicateSearches() {
    this.modalService.showCustomModal(ShareSearchesComponent, {width: '700px', data: {searchesList: this.mySearchList}});
  }
}
