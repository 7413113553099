import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MediaQueryService} from '../../services/media-query/media-query.service';
import {AlertController, ModalController} from '@ionic/angular';
import {MailsService} from '../../services/mails/mails.service';
import {Newsletter} from '../../interfaces/newsletter/newsletter';
import {MatSelectChange} from '@angular/material/select';
import {ArticleInterface} from '../../interfaces/articles/article.interface';
import {NewsletterTemplateComponent} from '../modals/newsletter-template/newsletter-template.component';
import {NewsletterPreviewModalComponent} from '../modals/newsletter-preview-modal/newsletter-preview-modal.component';
import {Utils} from '../../classes/Utils';
import {UserService} from '../../services/user/user.service';
import {takeUntil} from 'rxjs/operators';
import {SessionInterface} from '../../interfaces/session/session.interface';
import {SnackbarService} from '../../services/snackbar/snackbar.service';
import {ColorType, IconType} from "../parts/fe-icon/fe-icon.component";
import {ModalService} from "../../services/modal/modal.service";
import {ScopeSendNewsletterComponent} from "../modals/scope-send-newsletter/scope-send-newsletter.component";
import {ScopeCreateNewsletterComponent} from "../modals/scope-create-newsletter/scope-create-newsletter.component";
import {ScopeMail, ScopeMailList} from "../../interfaces/newsletter/ScopeMailList";
import {ScopePreviewNewsletterComponent} from "../modals/scope-preview-newsletter/scope-preview-newsletter.component";
import {LoaderService} from "../../services/loader/loader.service";

declare function loadStripo(template, externalLib, emailId, userId): any;
declare function compileMail(func): any;
declare function getHtmlCss(func): any;

@Component({
  selector: 'app-scope-news-letter',
  templateUrl: './scope-news-letter.component.html',
  styleUrls: ['./scope-news-letter.component.scss']
})
export class ScopeNewsLetterComponent implements OnInit {

public newsletters: Array<Newsletter> = new Array<Newsletter>();
public selectedNl: Newsletter;
@ViewChild('stripoSettingsContainer') stripoSettings: ElementRef;
@ViewChild('stripoPreviewContainer') stripoContainer: ElementRef;
private companyId: string;
private isScopeOnly: boolean;
public menuIconFiltre: string = 'keyboard_arrow_down';
protected noNlSelected: Boolean = true;
protected isLoading: Boolean = false;
protected nlTitle: String = "Choix d'une newsletter";
private broadcastList: ScopeMailList[] = [];
private mailList: ScopeMail[] = [];
private subscription = null;

  constructor(public mediaQueryService: MediaQueryService
      , private modalController: ModalController
      , private modalService: ModalService
      , private mailService: MailsService
      , private alertController: AlertController
      , private loaderService: LoaderService
      , private snackBarService: SnackbarService
      , private mailsService: MailsService
      , private userService: UserService) { }

  ngOnInit() {
    this.userService.getSessionDatas().pipe()
        .subscribe(
            (sessionData: SessionInterface) => {
              if (sessionData !== undefined) {
                this.companyId = sessionData.customerDetails.companyId;
                if(sessionData.isScope) {
                  this.isScopeOnly = true;
                }
              }
            }
        );

    this.mailsService.getNewsletters().subscribe(value => {
      if (value != null) {
        this.newsletters = value;
      }
    });

    this.mailService.getScopeMailLists().subscribe(broadcasts=> {
      this.broadcastList = [];
      if (broadcasts != null) {
        broadcasts.forEach(broadcast => {
          if (broadcast.mails.length > 0) {
            this.broadcastList.push(broadcast);
          }
        });
      }
    });

    this.mailService.getScopeEmails().subscribe(value => {
      this.mailList = value;
    });
  }

public ionViewWillEnter(): void {
    this.mailService.getNewsletters().subscribe(value => {
      this.newsletters = value;
      // if (!this.mailService.selectedTemplate && !this.selectedNl && this.newsletters.length > 0) {
      //   this.selectedNl = this.newsletters[0];
      //   this.mailService.getNewsletterAvailableArticles(this.selectedNl.id).subscribe(articles => {
      //     loadStripo({html: this.selectedNl.html, css: this.selectedNl.css}, this.getExternalLib(articles), this.selectedNl.id);
      //   });
      // }
    });

    if (!this.isScopeOnly && this.mailService.selectedTemplate) {
      this.mailService.createNewsletterFromTemplateAndFolder(this.mailService.selectedTemplate, this.mailService.selectedFolder).subscribe(value => {
      this.mailService.selectedTemplate = undefined;
      this.selectedNl = value;
      this.newsletters.push(value);
      this.mailService.getNewsletterAvailableArticles(value.id).subscribe(articles => {
        loadStripo({html: value.html, css: value.css}, this.getExternalLib(articles), value.id, this.companyId);
      });
    });
  }
}

public articleMap = new Map();
private getExternalLib(articles: Array<ArticleInterface>) {
    const self = this;
    this.articleMap.clear();
    articles.forEach(article => {
      this.articleMap.set('article_' + article.id + '', article.title + ' ' + article.corps);
    });
    const libArticles: string = this.mailService.getLibArticlesHtml(articles);
    return (function() {
      let externalLibrary;
      let dataSelectCallback;
      let cancelCallback;

      const close = function() {
        externalLibrary.style.visibility = 'hidden';
      };

      const cancelAndClose = function() {
        close();
        cancelCallback();
      };

      const initLibrary = function() {
        const div = document.createElement('div');
        div.innerHTML = '\
            <div id="externalSmartElementsLibrary" style="background-color: rgba(0,0,0,.5); overflow: hidden; position: fixed; top: 0; right: 0;  bottom: 0; left: 0; z-index: 1050; font-family: sans-serif;">\
                <div style="margin: 10px;">\
                <div style="background-color: #f6f6f6; border-radius: 17px 17px 30px 30px; max-width: 900px; margin: 0 auto;">\
                    <div style="padding: 15px; border-bottom: 1px solid #e5e5e5;">\
                        <div>\
                           <button class="close" type="button" style="cursor: pointer; background: transparent; border: 0; float: right; font-size: 21px; font-weight: bold; opacity: .2;">\
                                <span>×</span>\
                            </button>\
                            <h4 style="margin: 0; font-size: 18px; color: rgb(85, 85, 85);">Articles disponibles</h4>\
                        </div>\
                    </div>\
                    <div style="padding: 15px; border-bottom: 1px solid #e5e5e5;">\
                        <input id="inputArticles">\
                    </div>\
                    <div style="padding: 15px; height: 500px; overflow: auto;">' + libArticles + ' \
                    </div>\
                </div>\
            </div>';
        document.body.appendChild(div);
        const input = document.getElementById('inputArticles');
        function filterArticles(e) {
          // refresh display
          self.articleMap.forEach((value, key) => {
            document.getElementById(key).style.display = 'block';
          });
          const filter = e.target.value;
          self.articleMap.forEach((value, key) => {
            if (!value.includes(filter)) {
              document.getElementById(key).style.display = 'none';
            }
          });
        };
        input.addEventListener('input', filterArticles);
        externalLibrary = document.getElementById('externalSmartElementsLibrary');
        externalLibrary.querySelector('.close').addEventListener('click', cancelAndClose);
        document.querySelectorAll('.thumbnail')
            .forEach(thumbnail => thumbnail.addEventListener('click', function(e: any) {
              const exampleOfCallbackObject = {
                p_name: e.currentTarget.querySelector('p').innerText,
                p_price: e.currentTarget.querySelector('div').innerText,
                p_image: e.currentTarget.querySelector('a').innerText,
                p_old_price: e.currentTarget.querySelector('a').getAttribute('href'),
                p_description: e.currentTarget.querySelector('p').getAttribute('titlelink')
              };
              dataSelectCallback(exampleOfCallbackObject);
              close();
            }));
      };

      const renderLibrary = function() {
        if (!externalLibrary) {
          initLibrary();
        }
        externalLibrary.style.visibility = 'visible';
      };

      return {
        openLibrary: function(onDataSelectCallback, onCancelCallback, selectedModule) {
          // selectedModule - selected jQuery DOM element
          dataSelectCallback = onDataSelectCallback;
          cancelCallback = onCancelCallback;
          renderLibrary();
        }
      };
    })();
  }

  selectNewsletter(nl: Newsletter) {
    this.stripoSettings.nativeElement.innerHTML = '';
    this.stripoContainer.nativeElement.innerHTML = '';
    this.isLoading = true;
    this.selectedNl = nl;
    this.nlTitle = nl.name;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.noNlSelected = false;
    this.isLoading = false;
    const articles: Array<ArticleInterface> = new Array<ArticleInterface>();
    loadStripo({html: this.selectedNl.html, css: this.selectedNl.css}, articles, this.selectedNl.id, this.companyId);
  }

  saveNewsletter() {
    const truc = this;
    getHtmlCss(function () {
      const htmlCss = (window as any).htmlcss;
      truc.selectedNl.html = htmlCss.html;
      truc.selectedNl.css = htmlCss.css;
      truc.mailService.updateNewsletter(truc.selectedNl).subscribe(value => {
        // truc.selectedNl = value;
      });
    });
  }


  sendNewsletter() {
    compileMail(function() {
      const htmlCss = (window as any).htmlcss;

    });
  }

  protected previewNewsletter(): void {
    if (this.selectedNl != null) {

      this.modalService.showCustomModalWithActionOnClose(ScopePreviewNewsletterComponent,
          {data: {parent: this, newsletter: this.selectedNl}}, args => {
        this.openSendNewsletter();
      });

      // compileMail(function() {
      //   truc.showPreviewBridge((window as any).htmlcss);
      // });
    } else {
      this.snackBarService.showDangerSnackbar('Opération impossible', 'Vous devez sélectionner une newsletter.');
    }
  }

  showPreviewBridge(htmlCss: any) {
    this.showPreview(htmlCss);
  }

  async showPreview(htmlCss: any) {
    this.mailService.nl = this.selectedNl;
    this.mailService.nl.html = htmlCss.html;
    // const modal = await this.modalController.create({
    //   component: NewsletterPreviewModalComponent,
    //   cssClass: 'previewNlDialog',
    //   componentProps: {
    //   },
    //   backdropDismiss: true
    // });
    //
    // return await modal.present();

    this.modalService.showCustomModal(NewsletterPreviewModalComponent, {});
  }

  protected historizeNewsletter(): void {
    if (this.selectedNl != null) {
      this.modalService.confirmModal('Historisation de la newsletter',
          'Votre newsletter va être historisée, elle ne sera plus accessible pour modification ou envoi.<br/>Êtes-vous sur de vouloir continuer ?',
          args => {
        this.loaderService.showLoader(true, 'Historisation en cours...');
        this.mailService.historizeNewsletter(this.selectedNl.id).subscribe(value => {
          this.snackBarService.showSuccessSnackbar('Newsletter historisée', 'Votre newsletter a été historisée avec succès.');
          const index = this.newsletters.indexOf(this.selectedNl, 0);
          if (index > -1) {
            this.newsletters.splice(index, 1);
          }
          this.stripoSettings.nativeElement.innerHTML = '';
          this.stripoContainer.nativeElement.innerHTML = '';
          this.noNlSelected = true;
          this.selectedNl = null;
          this.nlTitle = "Choix d'une newsletter";
          this.loaderService.showLoader(false, "");
        });
      });
    } else {
      this.snackBarService.showDangerSnackbar('Opération impossible', 'Vous devez sélectionner une newsletter.');
    }
  }

  async saveAsTemplate() {
    if (this.selectedNl) {
      this.modalService.inputModal('Sauvegarder un nouveau template',
          'Votre newsletter va être sauvegardée en tant que template.<br/>Êtes-vous sur de vouloir continuer ?',
              'Nom du template', 'Nom du template', '', args => {
        this.confirmSaveTemplate(args);
      });
    } else {
      this.snackBarService.showDangerSnackbar('Opération impossible', 'Vous devez sélectionner une newsletter.');
    }
  }

  confirmSaveTemplate(templateName) {
    const self = this;
    const clone = Object.assign({}, this.selectedNl);
    clone.name = templateName;
    getHtmlCss(function () {
      const htmlCss = (window as any).htmlcss;
      clone.html = htmlCss.html;
      clone.css = htmlCss.css;
      self.mailService.saveAsTemplate(clone).subscribe(value => {
        self.confirmSaveasTemplate();
      });
    });
  }

  async confirmSaveasTemplate() {
    this.snackBarService.showSuccessSnackbar('Template sauvegardé', 'Votre template a été sauvegardé avec succès.');
  }

  protected readonly IconType = IconType;
  protected readonly alert = alert;

  onMenuFiltreClosed() {
    // this.menuOpenFiltre = false;
    this.menuIconFiltre = 'keyboard_arrow_down';
  }

  protected readonly ColorType = ColorType;

  onDropDownMenuOpenFiltre($event: MouseEvent) {
    $event.stopPropagation();
    this.menuIconFiltre = 'keyboard_arrow_up';
  }

  protected openSendNewsletter(): void {
    if (this.selectedNl != null) {
      this.modalService.showCustomModal(ScopeSendNewsletterComponent,
          {data: {newsletter: this.selectedNl, mailList: this.mailList, broadcastList: this.broadcastList}});
    } else {
      this.snackBarService.showDangerSnackbar('Opération impossible', 'Vous devez sélectionner une newsletter.');
    }
  }

  protected openCreateNewsletter(): void {
    this.modalService.showCustomModalWithActionOnClose(ScopeCreateNewsletterComponent,
        {}, newsletter => {
      this.newsletters.push(newsletter);
      this.selectNewsletter(newsletter);
    });
  }

  protected isNlSelected(): void {
    if (this.selectedNl == null) {
      this.snackBarService.showDangerSnackbar('Opération impossible',
          'Vous devez sélectionner une newsletter.');
    }
  }
}
