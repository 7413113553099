<main-toolbar  *ngIf="mediaQueryService.mobileQuery.matches"></main-toolbar>
<main-toolbar [sideNav]="snav" [class.scopeHeight]="sessionData.isScope"
              *ngIf="!mediaQueryService.mobileQuery.matches" style="min-height: 71px;"/>

<mat-sidenav-container class="sidenav-container appledebug sidenavBackDrop">

        <ion-router-outlet></ion-router-outlet>

        <mat-sidenav #snav
                     [ngClass]="{'small': smallSidenavDesktop && !mediaQueryService.mobileQuery.matches, 'mobile': mediaQueryService.mobileQuery.matches}"
                     [mode]="'over'"
                     [opened]="false"
                     [disableClose]="false"
                     [fixedInViewport]="true"
                     position="end" class=sideNavProfil>

                <ion-header no-border style="text-align: center">
                        <ion-toolbar
                                [ngClass]="{'mobile': mediaQueryService.mobileQuery.matches}">
                                <div style="display: flex; width: fit-content; margin: auto; gap: 16px;">
                                        <span class="mainTitle" style="margin: auto">Mon compte</span>

                                        <fe-button text="Déconnecter" color="DANGER" [iconType]="IconType.log_out"
                                                   icon="true" (click)="confirmLogout()" smallButton="true"/>
                                </div>




                        </ion-toolbar>
                </ion-header>
                <div class="sideNavProfilContainer">
                        <div class="sideNavProfilBloc">
                                <span class="veryBigTitleBold">Mes informations personnelles</span>
                                <div class="sideNavProfilBlocContent">
                                        <div class="sideNavProfilBlocSection">
                                                <span class="bigTextBold">Nom</span>
                                                <span>{{sessionData?.customerDetails.civility}} {{sessionData?.customerDetails.firstname}} {{sessionData?.customerDetails.lastname}}</span>
                                        </div>
                                        <div class="sideNavProfilBlocSection">
                                                <span class="bigTextBold">Société</span>
                                                <span>{{sessionData?.customerDetails.companyName}}</span>
                                        </div>
                                        <div class="sideNavProfilBlocSection">
                                                <span class="bigTextBold">Email</span>
                                                <span>{{sessionData?.customerDetails.email}}</span>
                                        </div>
                                </div>
                        </div>
                        <div style="display:flex; flex-direction: column; gap: 8px; margin: 28px 0;" *ngIf="!sessionData?.isScope">
<!--                        <div class="sideNavProfilBloc">-->
<!--                                <div class="sideNavProfilBlocContent" style="align-items: center;">-->
                                        <fe-button (click)="getMobileLoginInfos()" bold="true"
                                                   *ngIf="!isLettrePersoMC" type="SECONDARY" smallButton="true"
                                                   text="Recevoir mes identifiants pour l'application mobile"/>
                                        <fe-button bold="true" type="SECONDARY" smallButton="true"
                                                   *ngIf="sessionData?.customerDetails.mandatoryAuth"
                                                   (click)="changePassword(sessionData.customerDetails.mandatoryAuth)"
                                                   [text]="sessionData.customerDetails.mandatoryAuth ? 'Modifier ' +
                                                    'mon mot de passe' : 'Modifier votre mot de passe d\'accès'"/>
<!--                                </div>-->
<!--                        </div>-->
                        </div>
                        <div class="sideNavProfilBloc" *ngIf="!sessionData?.isScope">
                                <div style="display: flex; align-items: center;">
                                        <span class="veryBigTitleBold">Mon conseiller</span>
                                        <fe-button text="Aide" color="DANGER" icon="true" [iconType]="IconType.creditInfo"
                                                   (click)="openZendesk()" style="margin-left: auto;" smallButton="true"/>
                                </div>
                                <div class="sideNavProfilBlocContent">
                                        <div class="sideNavProfilBlocSection">
                                                <span class="bigTextBold">Nom</span>
                                                <span>{{sessionData?.salesPersonDetails.salesPersonFirstname}} {{sessionData?.salesPersonDetails.salesPersonName}}</span>
                                        </div>
                                        <div class="sideNavProfilBlocSection">
                                                <span class="bigTextBold">Email</span>
                                                <span>{{sessionData?.salesPersonDetails.salesPersonEmail}}</span>
                                        </div>
                                        <div class="sideNavProfilBlocSection">
                                                <span class="bigTextBold">Téléphone</span>
                                                <span>{{sessionData?.salesPersonDetails.salesPersonPhone}}</span>
                                        </div>
                                        <div class="sideNavProfilBlocSection">
                                                <fe-button (click)="contactMyCommercial()"
                                                           *ngIf="!isLettrePersoMC" smallButton="true"
                                                           text="Contacter mon conseiller"/>
                                        </div>
                                </div>
                        </div>
                        <div class="sideNavProfilBloc" *ngIf="!sessionData?.isScope">
                                <div style="display: flex; align-items: center;">
                                        <span class="veryBigTitleBold">Ma formule</span>
                                        <fe-button text="Modifier mon offre" type="SECONDARY" smallButton="true" bold="true"
                                                   (click)="showLocationDetails()" style="margin-left: auto;"/>
                                </div>
                                <div class="sideNavProfilBlocContent">
                                        <div class="sideNavProfilBlocSection">
                                                <span class="bigTextBold">Offre</span>
                                                <span>{{sessionData?.productOfferLabel}}</span>
                                        </div>
                                        <div class="sideNavProfilBlocSection">
                                                <span class="bigTextBold">Profil</span>
                                                <span>{{departements?.length + ' critères inclus'}}</span>
                                        </div>
                                        <div class="sideNavProfilBlocSection">
                                                <fe-button type="TERTIARY" text="Voir plus" bold="true"
                                                           (click)="showLocationDetails()" smallButton="true"/>
                                        </div>
                                </div>
                        </div>
                </div>

        </mat-sidenav>

</mat-sidenav-container>
